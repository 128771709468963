import { onLCP, onCLS } from 'web-vitals'
import { onINP } from 'web-vitals/attribution'

function drop (rate) {
  return Math.floor(Math.random() * rate) !== 0
}

function trackWebVital ({ name, value }) {
  global.dataLayer.push({
    event: 'trackable event',
    eventName: 'Web Vital',

    eventParams: {
      name,
      value: name === 'CLS' ? parseFloat(value.toFixed(4)) : Math.round(value)
    }
  })
}

function trackWebVitalWithAttribution ({ name, value, id, attribution }) {
  const { eventTarget, eventType } = attribution

  global.dataLayer.push({
    event: 'trackable event',
    eventName: 'Web Vital',
    eventParams: {
      name,
      value: Math.round(value),
      attribution: {
        eventTarget,
        eventType
      }
    }
  })
}

function initWebVitalTracking () {
  if (drop(10)) return

  onLCP(trackWebVital)
  onCLS(trackWebVital)
  onINP(trackWebVitalWithAttribution)
}

initWebVitalTracking()
